@use '@angular/material' as mat;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.
@import './app/styles/variables.scss';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$CareerScopeAdmin-primary: mat.m2-define-palette($md-careerscope, 500, 800, 300);
$CareerScopeAdmin-accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$CareerScopeAdmin-warn: mat.m2-define-palette(mat.$m2-red-palette);

$CareerScope-typography: mat.m2-define-typography-config($font-family: 'Mulish, sans-serif',
    $button: mat.m2-define-typography-level(1rem, 1.5rem, 500, $font-family: 'Mulish', $letter-spacing: 0),
    $body-1: mat.m2-define-typography-level(1rem, 1.5rem, 400, $font-family: 'Mulish', $letter-spacing: 0),
    $body-2: mat.m2-define-typography-level(1rem, 1.5rem, 400, $font-family: 'Mulish', $letter-spacing: 0),
    $headline-5: mat.m2-define-typography-level(2rem, 2.75rem, 400, $font-family: 'Mulish'),
    $headline-6: mat.m2-define-typography-level(1.35rem, 1.6rem, 400, $font-family: 'Mulish'), );

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$CareerScopeAdmin-theme: mat.m2-define-light-theme((color: (primary: $CareerScopeAdmin-primary,
        accent: $CareerScopeAdmin-accent,
        warn: $CareerScopeAdmin-warn,
      ),
      typography: $CareerScope-typography ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-typographies($CareerScope-typography);
@include mat.all-component-themes($CareerScopeAdmin-theme);




// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
// @include mat.legacy-typography-hierarchy($custom-typography);
// @include mat.all-legacy-component-typographies($custom-typography);
// @include mat.legacy-core();


/* You can add global styles to this file, and also import other style files */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

// Can test media print: https://stackoverflow.com/questions/9540990/using-chromes-element-inspector-in-print-preview-mode

@media print {
  header {
    display: none;
  }

  footer {
    display: none;
  }

  .beamer_defaultBeamerSelector {
    display: none !important;
  }
}

html,
body {
  height: 100%;
  font-size: 14px;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-icon {
  height: 24px;
  width: 24px;
}

.mat-mdc-dialog-container {
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, .85);
}


.mat-mdc-button mat-icon {
  height: 24px !important;
  width: 24px !important;
  font-size: 24px !important;
}

.title-bar {
  display: flex;
  flex-wrap: wrap;
  // border-bottom: 1px solid rgba(0, 0, 0, .1);
  margin-bottom: 10px;
  align-items: baseline;
}

.title-bar h1 {
  color: rgb(0, 48, 92);
  font-family: Mulish;
}

.title-bar .mat-mdc-button {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.page-description {
  margin: 0 0 2rem;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  p {
    max-width: 40rem;
    margin-right: 2rem;
  }
}

/* Global table style */

table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;

  th {
    text-align: left;
    vertical-align: middle;
  }

  td,
  th {
    padding: .75rem;
    vertical-align: top;
  }

  td {
    border-top: 1px solid #dee2e6
  }

  thead th[scope=col] {
    border-top: 0px;
    vertical-align: top;
  }

  th[scope=rowgroup] {
    font-weight: initial;
  }

  tbody+tbody {
    border-top: 1px solid #dee2e6;
  }

}

mat-calendar {

  td,
  th {
    border-top: 0px;
  }
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, .075);
  cursor: pointer;

  td {
    opacity: 1;
  }
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}




.delay {
  animation: fadeIn ease 3s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.mat-toolbar {
  --mat-toolbar-container-background-color: #{$cs-blue} !important;
  --mat-toolbar-container-text-color: rgba(255, 255, 255, 0.87) !important;
}





/* Material overrides that need to be at the top level */
.mdc-text-field {
  background-color: white !important;
}

.mat-drawer-container {
  background-color: white;
}

.mat-drawer-inner-container {
  display: flex !important;
  flex-direction: column;
}

.mat-mdc-card-header-text {
  width: 100% !important;
}

// Set default alignment in mat expansion panel
.mat-content {
  align-items: center !important;
}

.mat-mdc-optgroup-label {
  line-height: 2rem !important;
  height: auto !important;
}

// Align material table formatting with non-material table formatting
th.mat-mdc-header-cell,
td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  padding: .75rem !important;
  border-bottom-style: none !important;
}

tr.mat-mdc-row,
tr.mat-mdc-footer-row {
  height: inherit !important;
}

app-counselor-select {
  margin-left: auto;
}

:root {
  --max-width: 100%;
}

.container {
  width: 100%;
  margin: auto;
  box-sizing: border-box;
}

.container-width {
  max-width: var(--max-width);
}

.container-pad {
  padding: 0 16px;
}


@media (min-width: 840px) {
  :root {
    --max-width: 816px;
  }
}

@media (min-width: 1024px) {
  :root {
    --max-width: 976px;
  }
}

@media (min-width: 1600px) {
  :root {
    --max-width: 1400px;
  }
}

.inline-link {
  color: #004ec8;
  text-decoration: underline;
  cursor: pointer;
}